import { template as template_a58887bbda764f5abe0323e5d867aad3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a58887bbda764f5abe0323e5d867aad3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
